import React from 'react';
import './static/css/education.css';
import { Slide,Fade} from 'react-awesome-reveal';

const Education = () => {
  return (
	<Slide direction='up' triggerOnce duration={1000}>
    <section className="ftco-section ftco-no-pb" id="education-section" >
    	<div className="container">
    		<div className="row justify-content-center pb-5">
          <div className="col-md-10 heading-section text-center ">
          	<h1 className="big big-2">Education</h1>
            <h2 className="mb-4">Education</h2>
            <p></p>
          </div>
        </div>  
		<div className="col-12">
			<div className="timeline-wrap">
			<Fade cascade damping={0.5} triggerOnce>

				<div className="timeline-block">
					<div className="timeline-ico">
						<span className="icon-graduation-cap"></span>
					</div>
					<div className="timeline-header">
						<h5 style={{color:'#01bcd4 ',fontSize:'18px'}}>Kingston University, UK</h5>
						<h6>2022 - 2023</h6>
					</div>
					<div className="timeline-content">
						<h5 style={{fontSize:'18px'}}>BSc (Hons) Computer Science in Software Engineering</h5> 
						<br /><br /><br /><br />
					</div>
				</div> 
				<div className="timeline-block">
					<div className="timeline-ico">
						<span className="icon-graduation-cap"></span>
					</div>
					<div className="timeline-header">
						<h5 style={{color:'#01bcd4 ',fontSize:'18px'}}>SQA, UK</h5>
						<h6>2019 - 2022</h6>
					</div>
					<div className="timeline-content">
						<h5 style={{fontSize:'18px'}}>Advanced Diploma in Computing: Software Development</h5> 
						<br /><br /><br /><br />
					</div>
				</div>  
				<div className="timeline-block">
					<div className="timeline-ico">
						<span className="icon-graduation-cap"></span>
					</div>
					<div className="timeline-header">
						<h5 style={{color:'#01bcd4 ',fontSize:'18px'}}>St. Peter's Colege, Sri&nbsp;Lanka</h5>
						<h6>2008 - 2021</h6>
					</div>
					<div className="timeline-content">
						<h5 style={{fontSize:'18px'}}>G.C.E Advanced Level [Physical Science]</h5> 
						<br /><br />
					</div>
				</div>   
				</Fade>
			</div>   			
		</div>  
		</div>   
    </section>
	</Slide>
  )
}

export default Education
