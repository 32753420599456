import React from 'react'; 
import './App.css';  
import './static/css/open-iconic-bootstrap.min.css';
import './static/css/animate.css'; 
import './static/css/owl.theme.default.min.css';
import './static/css/magnific-popup.css';
import './static/css/aos.css';
import './static/css/ionicons.min.css';
import './static/css/flaticon.css';
import './static/css/icomoon.css';
import './static/css/style.css'; 
import 'bootstrap';
import $ from 'jquery'; 
import 'jquery-ui-dist/jquery-ui'; 
import Home from './Home';
import About from './About';
import Education from './Education';
import Experience from './Experience';
import Skills from './Skills';
import Projects from './Projects';
import Contact from './Contact';
import Nav from './Nav';
import Footer from './Footer';
import { Fade} from 'react-awesome-reveal'; 

function App() { 
  var loader = function() {
		setTimeout(function() { 
			if($('#ftco-loader').length > 0) {
				$('#ftco-loader').removeClass('show');
			}
		}, 1);
	};
	loader();

  $('nav .dropdown').hover(function(){
		var $this = $(this); 
		$this.addClass('show');
		$this.find('> a').attr('aria-expanded', `true`); 
		$this.find('.dropdown-menu').addClass('show');
	}, function(){
		var $this = $(this); 
			$this.removeClass('show');
			$this.find('> a').attr('aria-expanded', `false`); 
			$this.find('.dropdown-menu').removeClass('show'); 
	});


	$('#dropdown04').on('show.bs.dropdown', function () {
	  console.log('show');
	});
 
	var scrollWindow = function() {
		$(window).scroll(function(){
			var $w = $(this),
					st = $w.scrollTop() ?? 0,
					navbar = $('.ftco_navbar'),
					sd = $('.js-scroll-wrap');

			if (st > 150) {
				if ( !navbar.hasClass('scrolled') ) {
					navbar.addClass('scrolled');	
				}
			} 
			if (st < 150) {
				if ( navbar.hasClass('scrolled') ) {
					navbar.removeClass('scrolled sleep');
				}
			} 
			if ( st > 350 ) {
				if ( !navbar.hasClass('awake') ) {
					navbar.addClass('awake');	
				}
				
				if(sd.length > 0) {
					sd.addClass('sleep');
				}
			}
			if ( st < 350 ) {
				if ( navbar.hasClass('awake') ) {
					navbar.removeClass('awake');
					navbar.addClass('sleep');
				}
				if(sd.length > 0) {
					sd.removeClass('sleep');
				}
			}
		});
	};
	scrollWindow(); 

  return ( 
    <div>
	  
	<Nav></Nav>
    
	<Home></Home>
    <About></About>
    <Education></Education>
	<Experience></Experience>
    <Skills></Skills>
	{/* <Projects></Projects>  */}
	<Fade cascade triggerOnce>
    <section className="ftco-section img margin-top" style={{backgroundImage:"url(" + require(`./static/images/bg_3.jpg`)+ ")"}}>
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-7  text-center">
					<h2>I'm <span>Available</span> for freelancing</h2>
					<p>As a freelance software engineer, I leverage my deep technical expertise and problem-solving skills to develop innovative and efficient software solutions that meet my clients' needs.
						{/* , while providing exceptional service, clear communication, and delivering projects on time and within budget. */}
						</p>
					<p className="mb-0"><a href="#contact-section" className="btn btn-primary py-3 px-5">Hire me</a></p>
				</div>
			</div>
		</div>
	</section>
	</Fade>

    <Contact></Contact>
		

    <Footer></Footer>

  <div id="ftco-loader" className="show fullscreen"><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth={4} stroke="#eeeeee"/><circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth={4} strokeMiterlimit="10" stroke="#F96D00"/></svg></div>
    
  </div>
  );
}

export default App;
