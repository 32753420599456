import React from 'react'
import { Slide,Fade} from 'react-awesome-reveal'; 
import html from './static/images/logos/html.png';
import css from './static/images/logos/css.png';
import js from './static/images/logos/js.png';
import python from './static/images/logos/python.png';
import java from './static/images/logos/java.png';
import php from './static/images/logos/php.png';
import typescript from './static/images/logos/typescript.png';
import react from './static/images/logos/react.png';
import django from './static/images/logos/django.png';
import laravel from './static/images/logos/laravel.png';
import vue from './static/images/logos/vue.png';
import springboot from './static/images/logos/springboot.png';
import bootstrap from './static/images/logos/bootstrap.png';
import postgres from './static/images/logos/postgresql.png';
import mysql from './static/images/logos/mysql.png';
import sqllite from './static/images/logos/sqlite.png';

const Skills = () => {
  return (
    <Slide direction='up' triggerOnce duration={1000}>
    <section className="ftco-section" id="skills-section" style={{paddingTop:0}}>
		<div className="container">
			<div className="row justify-content-center pb-5">
                <div className="col-md-12 heading-section text-center ">
                    <h1 className="big big-2">Skills</h1>
                    <h2 className="mb-4">Technical Skills</h2>
                    <p></p>
                </div>
            </div>
            <Fade cascade triggerOnce>
            <div className="row">
                <div className="col-md-12"  style={{textAlign:'center'}}> 
                    <span className="big-yellow">Languages</span> 
                    <br /><br /> <br />
                    <div className="row d-flex contact-info mb-5"> 
                        <div className="col-6 col-lg-3 mb-5"  style={{textAlign:'center'}}> 
                            <img src={html} alt={"html"}  style={{borderRadius: '50%',width:'100px'}} /> 
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={css} alt={"css"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={js} alt={"js"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={python} alt={"python"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>

                        <div className="col-6 col-lg-4 mb-5"  style={{textAlign:'center'}}> 
                            <img src={java} alt={"java"}  style={{borderRadius: '50%',width:'100px'}} /> 
                        </div>
                        <div className="col-6 col-lg-4 mb-5"> 
                            <img src={php} alt={"php"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-12 col-lg-4 mb-5"> 
                            <img src={typescript} alt={"typescript"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div> 
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12"  style={{textAlign:'center'}}> 
                    <span className="big-yellow">Frameworks</span> 
                    <br /><br /> <br />
                    <div className="row d-flex contact-info mb-5">
                        <div className="col-6 col-lg-3 mb-5"  style={{textAlign:'center'}}> 
                            <img src={react} alt={"react"}  style={{borderRadius: '50%',width:'100px'}} /> 
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={django} alt={"django"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={laravel} alt={"laravel"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-6 col-lg-3 mb-5"> 
                            <img src={vue} alt={"vue"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>

                        <div className="col-6 col-lg-6 mb-5"  style={{textAlign:'center'}}> 
                            <img src={springboot} alt={"springboot"}  style={{borderRadius: '50%',width:'100px'}} /> 
                        </div>
                        <div className="col-6 col-lg-6 mb-5"> 
                            <img src={bootstrap} alt={"php"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div> 
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-12"  style={{textAlign:'center'}}> 
                    <span className="big-yellow">Databases</span> 
                    <br /><br /> <br />
                    <div className="row d-flex contact-info mb-5">
                        <div className="col-6 col-lg-4 mb-5"  style={{textAlign:'center'}}> 
                            <img src={postgres} alt={"postgres"}  style={{borderRadius: '50%',width:'100px'}} /> 
                        </div>
                        <div className="col-6 col-lg-4 mb-5"> 
                            <img src={mysql} alt={"mysql"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div>
                        <div className="col-12 col-lg-4 mb-5"> 
                            <img src={sqllite} alt={"sqllite"}  style={{borderRadius: '50%',width:'100px'}} />
                        </div> 
                    </div>
                </div>
            </div>
            </Fade>
            {/* <div className="row">
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>HTML</h3>
                        <div className="progress">
                            <div className="progress-bar color-1" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>CSS</h3>
                        <div className="progress">
                            <div className="progress-bar color-2" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>Javascript</h3>
                        <div className="progress">
                            <div className="progress-bar color-3" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>Typescript</h3>
                        <div className="progress">
                            <div className="progress-bar color-4" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>Bootstrap</h3>
                        <div className="progress">
                            <div className="progress-bar color-5" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 animate-box">
                    <div className="progress-wrap ">
                        <h3>Wordpres</h3>
                        <div className="progress">
                            <div className="progress-bar color-6" role="progressbar" aria-valuenow={100}
                            aria-valuemin={0} aria-valuemax={100} style={{width:'100%'}}> 
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    </Slide>
  )
}

export default Skills
